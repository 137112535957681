import React from "react"


class HandleData extends React.Component{

    static MedTipo = () => {
        const medTipo = []
        medTipo[0] = {id: 0, name: 'Sem Classificação'}
        medTipo[1] = {id: 2, name: 'Nutracêuticos'}
        medTipo[2] = {id: 3, name: 'Medicamentos'}
        medTipo[3] = {id: 1, name: 'Dermocosméticos'} 
        return medTipo
    } 

    static handleSacheFormula = (formula, rotulo) => {
        if(rotulo == 'sc70') {
            formula = formula.split(';')
            console.log(formula)
            formula = formula[0]
        }
        return formula

    }

    static handleDataToPDF = (code, firstData, formula) => {
        return {
            code: code,
            name: this.handleName(this.capitalLetter(firstData.NOME_PACIENTE)),
            qtdy: this.getDosagemOrDosagemEdit(firstData),
            namereg: `Sra(o). ${firstData.NOME_PACIENTE}`,
            reg: `Reg ${firstData.REGISTRO_NRO}`,
            req: `Req ${firstData.FILIAL_ORIGEM} ${firstData.REQUISICAO_NRO} ${firstData.REQUISICAO_SERIE}`,
            componentes: formula,
            posologia: ((
                firstData.ETIQUETA_ID_ROTULO == 'PMLEAV' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMMASK' || 
                firstData.ETIQUETA_ID_ROTULO == 'SH-COND' || 
                firstData.ETIQUETA_ID_ROTULO == 'LEAVIN' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMMASK' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMLEAVIN' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMTON' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMCNDP' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMSHCONPQ' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMCNDG' || 
                firstData.ETIQUETA_ID_ROTULO == 'PMSHG' || 
                firstData.ETIQUETA_ID_ROTULO == 'SH-COND' || 
                firstData.ETIQUETA_ID_ROTULO == 'LEAVIN' || 
                firstData.ETIQUETA_ID_ROTULO == 'COSMGR' || 
                firstData.ETIQUETA_ID_ROTULO == 'COSMPQ' || 
                firstData.ETIQUETA_ID_ROTULO == 'MASCARA' || 
                firstData.ETIQUETA_ID_ROTULO == 'PUMPCMP' || 
                firstData.ETIQUETA_ID_ROTULO == 'PUMPCMG' || 
                firstData.ETIQUETA_ID_ROTULO == 'SH-CND' ||  
                firstData.ETIQUETA_ID_ROTULO == 'PMSHCONDPQ' ||  
                firstData.ETIQUETA_ID_ROTULO == 'SH-CND') ? 
            ' ' : '<b>POSOLOGIA:</b>') + this.getPosologiaOrPosologiaEdit(firstData),
            doctor: firstData.PRESCRITOR_NOME,
            crm:`${firstData.PRESCRITOR_TIPO}: ${firstData.PRESCRITOR_CRM_NRO}`,
            man:`Man: ${this.dateToBr(firstData.DATA_PRODUCAO)}`,
            val:`Val: ${this.dateToBr(firstData.DATA_VALIDADE_PRODUCAO)}`,
            qtdeProduto: this.handleQtdeProduto(firstData),
            farmresp: this.handleFarmResp(firstData),
            matriz: this.handleAddress(firstData),
            ativosFormula: this.handleAtivoFormula(firstData),
            tituloFormula: typeof firstData.TITULO_FORMULA == 'string' ? this.capitalLetter(firstData.TITULO_FORMULA) : '',
            descrProduto: typeof firstData.DESCR_PRODUTO == 'string' ? this.capitalLetter(firstData.DESCR_PRODUTO) : '',
            etiqueta: typeof firstData.ETIQUETA_ID_ROTULO == 'string' ? firstData.ETIQUETA_ID_ROTULO.toLowerCase() : 'r160',
            segment: typeof firstData.SEGMENTO == 'string' ? firstData.SEGMENTO.toLowerCase() : ''
        }
    }

    

    static getMedTipoByKey = (id) => {
        const item = HandleData.MedTipo().filter((item, index) => { return item.id == id})[0].name
        if(typeof item != 'undefined'){
            return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }
        return ''
    }

    static handleAtivoFormula(firstData){
        if(firstData.QTDE_ATIVOS == 0){
            return ""
        }

        if(firstData.QTDE_ATIVOS == 1){
            return firstData.ATIVOS_DA_FORMULA
        }

        if(firstData.QTDE_ATIVOS > 1){
            return ""
        }

    }

    static handleQtdeProduto(firstData){
        let qtdeProduto = (firstData.DOSAGEM_DA_FORMULA == "" || firstData.DOSAGEM_DA_FORMULA == undefined || typeof firstData.DOSAGEM_DA_FORMULA == 'object') ? '' : firstData.DOSAGEM_DA_FORMULA
        return HandleData.decodeUtf8(qtdeProduto)
    }

    static handleFarmResp(firstData){
        return `${firstData.FARMACEUTICA_RESP} <br /> CRF: ${this.maskCRF(firstData.FARMACEUTICA_CRF)} / CNPJ: ${this.maskCNPJ(firstData.FILIAL_ESTOQUE_CNPJ)}`;
    }

    static maskCNPJ(cnpj){
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    }

    static maskCRF(crf){
        return crf.replace(/^(\d{2})(\d{3})/, "$1.$2")
    }

    static handleAddress(firstData){
    
        let address = `${firstData.FILIAL_ESTOQUE_ENDERECO}, ${firstData.FILIAL_ESTOQUE_NRO} - ${firstData.FILIAL_ESTOQUE_BAIRRO} - ${firstData.FILIAL_ESTOQUE_MUNICIPIO} - ${firstData.FILIAL_ESTOQUE_UF} - CEP: ${firstData.FILIAL_ESTOQUE_CEP} Fone: (${firstData.FILIAL_ESTOQUE_DDD}) ${firstData.FILIAL_ESTOQUE_TELEFONE}`

        return HandleData.capitalLetter(address)
    }

    static dateToBr = (data) => {
        let date = data.split('-')
        if(typeof date[2] == 'undefined') {
            return date;
        }

        return `${date[2]}/${date[1]}/${date[0]}`
    }

    static capitalLetter = (str) => {
        str = str.toLowerCase()
        str = str.split(" ");
        for (var i = 0, x = str.length; i < x; i++) {
            if(typeof str[i][0] == 'string'){
                str[i] = str[i][0].toUpperCase() + str[i].substr(1);
            }
        }
        return str.join(" ");
    }


    static handleData = (data) => {
        let keys = Object.keys(data)
        let returnData = keys.map((name) => {
            return data[name]
        })
        return returnData;
    }   

    static getFormulaOrFormulaEdit = (data, firstData) => {
        var formula = data.map((item, index) => {
            return `${item.DESCR_PRODUTO} ${item.QTDE_PRODUTO}${item.UNIDADE_MEDIDA_PRODUTO}; `
        }).join('')

        if(firstData.EDICAO_FORMULA != '' && firstData.EDICAO_FORMULA != undefined){
            formula = firstData.EDICAO_FORMULA
        }

        if(typeof formula === 'string'){
            formula = formula.toUpperCase()
        }

        formula = HandleData.roundNumberFloat(formula)
        formula = HandleData.decodeUtf8(formula)

        return formula;
    }

    static roundNumberFloat(formula){
        var arrayNumber = formula.match(/[+-]?\d+(\.\d+)?/g);
        if(arrayNumber == null) { return formula }
        arrayNumber.map((item, index) => {
            if(!Number.isInteger(Number(item))){
                formula = formula.replace(item, Number(item).toFixed(3))
            }
        })
        return formula
    }

    static getPosologiaOrPosologiaEdit = (firstData) => {
        let posologia = (firstData.EDICAO_POSOLOGIA == "" || firstData.EDICAO_POSOLOGIA == undefined) ? firstData.POSOLOGIA : firstData.EDICAO_POSOLOGIA
        if(typeof posologia == 'string'){
            posologia = posologia.toUpperCase()
            return HandleData.decodeUtf8(posologia.toUpperCase())
        }
    }

    static getDosagemOrDosagemEdit = (firstData) => {
        let dosagem = (firstData.EDICAO_DOSAGEM == "" || firstData.EDICAO_DOSAGEM == undefined || typeof firstData.EDICAO_DOSAGEM == 'object') ? `${parseInt(firstData.TOTAL_DE_CAPSULAS)} ${firstData.UNIDADE_DE_VOLUME}` : firstData.EDICAO_DOSAGEM
        return HandleData.decodeUtf8(dosagem.toUpperCase())
    }

    static dataIsValid = (data) => {
        return data.RECORDSET == undefined
    }

    static encodeUtf8 = (s) =>  {
        try{
            return unescape( encodeURIComponent( s ) );
        }catch{
            return s
        }
    }
    
    static decodeUtf8 = (s) => {
        try{
            return decodeURIComponent( escape( s ) );
        }catch{
            return s
        }
    }

    static handleName(name){
        if(name.includes(" ")){
            name = name.split(" ")
            if(name.length > 1){
                name = `${name[0]} ${name[name.length-1]}`    
            }
        }
        return name;
    }

    static handleNameButton(name){
        if(typeof name  == 'string'){
            return HandleData.capitalLetter(name.replaceAll('_', ' '))
        }
        return name
    }
}

export default HandleData;

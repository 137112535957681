import React from "react"

class Util extends React.Component{

    static isProd(){
        return !Util.isHml()
    }

    static isHml(){
        const host = window.location.host
        const isLocalhost = (host.indexOf('localhost') === 0)
        const isHml = (host.indexOf('hml') === 0)
        return (isLocalhost || isHml)
    }
}

export default Util;
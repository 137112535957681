
const Authentication = {
    // cpf: "22233311100",
    // password: 'almofariz12344321',
    cpf: "",
    password: 'almofariz12344321',
    handleAuthentication: (data, password) => {
        return data.RETORNO == 'OK' && password == Authentication.password
    },
    authenticationSuccess: (data) => {
        Authentication.setSessionAuthentication()
        Authentication.setSessionAuthenticationData(data)
        window.location.href = '/home'
    },
    setSessionAuthenticationData : (data) => {
        sessionStorage.setItem("dataUser", JSON.stringify(data));
    },
    getSessionAuthenticationData(){
        return JSON.parse(sessionStorage.getItem("dataUser"))
    },
    setSessionAuthentication : () => {
        sessionStorage.setItem("isAuthenticated", true);
    },
    removeSessionAuthentication : () => {
        sessionStorage.setItem("isAuthenticated", false);
    },
    getSessionAuthentication : () => {
        return sessionStorage.getItem("isAuthenticated")
    },
}

export default Authentication
import React from "react"
import Api from '../../app/api'
import HandleData from '../../util/handle_data'
import Modal from 'react-awesome-modal';

class Rotulo extends React.Component{

    state = {
        data: null,
        firstData: null,
        makePdf: null,
        formula: null,
        modal : false,
        dataToPdf: null,
        buttons: [],
        segments: [],
    }
    
    constructor(props){
        super(props)

        this.nameRotulo = props.name
        this.code = props.code
        this.segment = props.segment
        this.data = null

        Api.getBusca(props.code, (data) => {
            this.setState({ data: HandleData.handleData(data) })
            this.setState({ firstData: this.state.data[0] })
            this.setState({ formula : HandleData.getFormulaOrFormulaEdit(this.state.data, this.state.firstData) })
            this.renderPDF()
        }, () => {
            this.setState({modal: true})
        })
    }

    handleNameEtiqueta(data){
        let segment = this.data.segment == '' ? this.segment : this.data.segment
        let etiqueta = this.data.segment == '' ? data.etiqueta :    "alm_" + data.etiqueta

        return typeof segment != 'undefined' ? `${etiqueta}-${segment}` : etiqueta
    }


    renderPDF = (etiqueta) => {
        this.data = HandleData.handleDataToPDF(this.code, this.state.firstData, this.state.formula)
        this.setState({dataToPdf: this.data})
        let handleEtiqueta = etiqueta == undefined ? this.handleNameEtiqueta(this.data) : etiqueta

        if(this.segment == '' || this.segment == undefined){
            this.segment = this.data.segment
        }

        this.data = this.handleGetJustFirstComponents(handleEtiqueta, this.data)

        console.log(handleEtiqueta, this.segment, this.data)

        Api.makePdf(handleEtiqueta, this.data, (makePdf) => {
            this.setState({
                makePdf: makePdf, 
                key: this.state.key++,
                buttons: makePdf.buttons,
                segments: makePdf.segments,
            });        
        })
    }

    handleGetJustFirstComponents = (etiqueta, data) => {
        if(data.componentes == '') return data
        if(etiqueta == 'sc70'){
            data.componentes = data.componentes.split(';')[0] + "; + componentes"
        }
        if(etiqueta == 'sc140'){
            data.componentes = data.componentes.split(';')[0] + "; + componentes"
        }
        return data
    }

    closeModal = () => {
        this.setState({modal: false})
        window.location.href = '/home'
    }
    
    render = () => {
        let {makePdf} = this.state
        let {firstData} = this.state

        return (
            <section>
                {
                this.data == null ?  
                    <div className="lds-dual-ring"></div>  
                    : 
                    (
                        <div>     
                            {
                                this.segment == '' ?
                                (
                                    <div className="colors">
                                    {
                                        this.state.segments != null ? this.state.segments.map((segment, key) => {
                                            return (
                                                <button key={key} onClick={() => { this.renderPDF(segment.goTo) } } value={segment.name} className={segment.color}>{HandleData.handleNameButton(segment.name)}</button>
                                            )
                                        })
                                        : null
                                    }
                                    </div>
                                ) : null
                            }
                            
                            <div className="colors">
                            {
                                this.state.buttons != null ? this.state.buttons.map((button, key) => {
                                    return (
                                        <button key={key} onClick={() => { this.renderPDF(button.goTo) } } value={button.name} className={this.segment == 'shampoo' ? 'selected' : ''}>{HandleData.handleNameButton(button.name)}</button>
                                    )
                                })
                                : null
                            }    
                            </div>
                            {
                                makePdf != null ? (
                                    <>
                                        <iframe id="printf"src={`${makePdf.file}#zoom=193`} type="application/pdf" className="viewPDF" id="viewPDF"/>
                                        <p> 
                                            <b>Rotulo:</b> {typeof firstData.ETIQUETA_ID_ROTULO == 'object' ? 'Sem rotulo definido' : firstData.ETIQUETA_ID_ROTULO}
                                            - <b>Segmento:</b> {typeof firstData.SEGMENTO == 'object' ? 'Sem segmento definido' : firstData.SEGMENTO}
                                            - <b>Fórmula padrão :</b> {typeof firstData.COD_FORMULA_PADRAO == 'object' ? 'Sem fórmula padrão definido' : firstData.COD_FORMULA_PADRAO} 
                                        </p>
                                    </>
                                ) :  this.segment == '' ? <div className="lds-dual-ring"></div>  : null
                            }
                            
                        </div>
                    )
                }

                <Modal visible={this.state.modal} width="200" height="180" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                    <div>
                        <p className="popupclose-title">Número Inválido</p>
                        <a className="popupclose-close" onClick={() => this.closeModal()}>X</a>
                    </div>
                </Modal>
            </section> 
        )
    }

}

export default Rotulo;
import React from "react";
import { Row, Button } from 'react-materialize';
import '../../css/print.css';
import Rotulo from '../rotulo/rotulo'

class Print extends React.Component{ 

  params = null

  constructor(props) {
    super(props)
    this.params = this.props.match.params
  }

  render = () => {
    return(
      <Row>
        <div className="detail-page">
            Imprimir Rótulo
        </div>
        <div className="base-label">
          <div className="label">
            <h4><strong>Requisição:</strong> { this.params.id }</h4>
            <Rotulo code={this.params.id} segment={this.params.segment} />
          
            <Button onClick={() => {
              let src = window.$('#viewPDF').attr('src')
              window.printJS(src)

              window.$('#bto-print').html('aguarde...')
              window.setTimeout(() => {
                window.$('#bto-print').html('Imprimir')
              }, 2000)

            }} className="button-print-print button-white" id="bto-print">Imprimir</Button>
            
            <br />
            <Button onClick={() => {
              window.location.href = '/home'
            }} className="button-print-print button-white" style={{width: 200}}>Voltar</Button>
            
          </div>
        </div>
      </Row>
    )
  }
}

export default Print;
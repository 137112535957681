import React from "react";
import { Row } from 'react-materialize';


const Home = () => (
  <Row>
    <a className="button button-book" href='/consult/print'>
      <span className="icon icon-book"></span>
      <h5>Consultar Rótulo</h5>
    </a>

    <a className="button button-pen" href='/consult/confirm'>
      <span className="icon icon-pen"></span>
      <h5>Imprimir/Editar Rótulo</h5>
    </a>
  </Row>
);

export default Home;
import React from "react";
import { Row, Button } from 'react-materialize';
import '../../css/print.css';
import Rotulo from '../rotulo/rotulo'

class Confer extends React.Component{ 

  params = null

  constructor(props) {
    super(props)
    this.params = this.props.match.params
  }

  render = () => {
    return(
      <Row>
        <div className="detail-page">
            Consultar Rótulo
        </div>
        <div className="base-label">
          <div className="label">
            <h4><strong>Requisição:</strong> { this.params.id }</h4>
            <Rotulo code={this.params.id} segment={this.params.segment} />
          
            <Button onClick={() => {
              window.location.href = '/home'
            }} className="button-print-print button-white" id="bto-print">Voltar</Button>
            
          </div>
        </div>
      </Row>
    )
  }
}

export default Confer;
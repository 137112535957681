import React from "react";
import { Row, Button } from 'react-materialize';
import '../../css/print.css';
import Api from '../../app/api'
import ReadMedia from '../read_media/read_media'
import Modal from 'react-awesome-modal';
import HandleData from '../../util/handle_data'
import FormulaView from '../formulaview/formulaview'

class Confirm extends React.Component{ 

  params = null
  state = {
    posologia: 'carregando...',
    formula: null,
    dosagem: 'carregando...',
    firstData: null,
    data: null,
    modal : false,
    medTipo: 0
  }

  constructor(props) {
    super(props)
    this.params = this.props.match.params

    Api.getBusca(this.params.id, (dataResponse) => {
      let handleData = HandleData.handleData(dataResponse)
      let firstData = handleData[0]
      this.setState({
        firstData: firstData,
        data: handleData,
        formula: HandleData.getFormulaOrFormulaEdit(handleData, firstData),
        posologia: HandleData.getPosologiaOrPosologiaEdit(firstData),
        dosagem: HandleData.getDosagemOrDosagemEdit(firstData),
        medTipo: typeof firstData.MEDICAMENTO_TIPO == 'undefined' ? 0 : firstData.MEDICAMENTO_TIPO
      })
    }, () => {
      this.setState({modal: true})
    })
  }

  closeModal = () => {
    this.setState({modal: false})
    window.location.href = '/consult/confirm'
  }
  

  sendApi = () => {
    let {posologia} = this.state
    let formula = this.clearFormula(this.state.formula)
    let {dosagem} = this.state
    let {medTipo} = this.state

    if(medTipo == 0){
      medTipo = 3
    }

    
    window.$('#buttonSave').html('Aguarde...')
    Api.editRotulo(this.params.id, posologia, formula, dosagem, medTipo, (data) => {
      if(data.RETORNO == 'OK'){
        if(this.state.medTipo == 0){
          window.location.href = `/print/${this.params.id}`
        }else{
          window.location.href = `/print/${this.params.id}/${HandleData.getMedTipoByKey(this.state.medTipo)}`
        }
      } else {
        alert("Ocorreu um erro ao editar o rotulo, por favor tente novamente!")
        window.$('#buttonSave').html('Salvar')
      }
    })
  }

  clearFormula = (formula) => {
    formula = formula.replaceAll(';;', '')
    formula = formula.replaceAll('; ;', '')
    formula = formula.replaceAll(' ;;', '')
    formula = formula.replaceAll(';; ', '')
    formula = formula.replaceAll('; ; ', '')
    formula = formula.replaceAll(' ; ; ', '')
    if(formula.charAt(0) == ';'){
      formula = formula.substring(1);
    }
    formula = formula.replaceAll('\n', '')
    formula = formula.replaceAll(';', '; ')
    return formula;
  }

  render = () => {
    return(
      <Row>
        <div className="detail-page"> Imprimir/Editar Rótulo </div>

        <center style={{marginTop: 5}}><b>Requisição</b>: {this.params.id}</center>

        <div className="confirm" style={{display: 'flex'}}>
          <div style={{flex: '25%'}}  className="edit-canvas">
            <div style={{textAlign: 'left'}}>
              <p><b>Nome do Paciente:</b> <br />{this.state.firstData != null ? this.state.firstData.NOME_PACIENTE : 'carregando...'}</p><br />
              <p><b>Nome do Cliente:</b> <br />{this.state.firstData != null ? this.state.firstData.NOME_CLIENTE : 'carregando...'}</p><br />
              <p><b>Nome do Prescritor:</b> <br />{this.state.firstData != null ? this.state.firstData.PRESCRITOR_NOME : 'carregando...'}</p><br />
              <p><b>Data Retirada:</b> <br />{this.state.firstData != null ? this.state.firstData.DATA_PARA_RETIRADA : 'carregando...'}</p><br />
              <p><b>Tipo Embalagem:</b> <br />{this.state.firstData != null ? this.state.firstData.DESCRICAO_EMBALAGEM : 'carregando...'}</p><br />
              <p><b>Tipo de Capsula:</b> <br />{this.state.firstData != null ? this.state.firstData.DES_CAPSULA : 'carregando...'}</p><br />
              <p><b>Validade:</b> <br />{this.state.firstData != null ? HandleData.dateToBr(this.state.firstData.DATA_VALIDADE_PRODUCAO) : 'carregando...'}</p>
              <br />
            </div>
          </div>

          <div style={{flex: '50%'}} >
            { this.state.data == null ? '' : <ReadMedia data={this.state.firstData}  /> }
          </div>

          <div style={{flex: '25%'}}  className="edit-canvas">
            <div>
              <h6>Fórmula</h6>
              <FormulaView data={this.state.formula} updateDate={(formula) => {console.log(formula); this.setState({formula: formula})}} />
            </div>

            <div>
              <h6>Posologia</h6>
              <textarea value={this.state.posologia} onChange={(evt, value) => this.setState({posologia: evt.target.value})}></textarea>
            </div>

            <div>
              <h6>Dosagem por Volume ou Quantidade</h6>
              <textarea style={{height:50, minHeight: 50}} value={this.state.dosagem} onChange={(evt, value) => this.setState({dosagem: evt.target.value})}></textarea>
            </div>

            <div>
            
              <>
                <h6>Tipo de medicamento</h6>
                <select onChange={(evt, value) => this.setState({medTipo: evt.target.value})} name="select" style={{height:50, minHeight: 50, width: '100%'}} >
                  {HandleData.MedTipo().map((item, index) => {
                    return <option selected={this.state.medTipo == item.id} value={item.id} key={index}>{item.name}</option>
                  })}
                </select>
              </>
            
             
            </div>

            <div>
              <br />
              <Button style={{fontSize: 8, padding:'0 10px'}} onClick={() => {window.history.back()}} disabled={this.state.firstData == null} waves='light' className="left button-white" id="buttonBack">Voltar</Button>
              <Button style={{fontSize: 12, padding:'0 10px'}} onClick={() => {this.sendApi()}} disabled={this.state.firstData == null} waves='light' className="right green-btn button-white" id="buttonSave">Salvar/Visualizar</Button>
            </div>
          </div>
        </div>

        <Modal visible={this.state.modal} width="200" height="180" effect="fadeInUp" onClickAway={() => this.closeModal()}>
          <div style={{textAlign: 'center'}}>
            <p className="popupclose-title">Número Inválido</p>
            <a className="popupclose-close" onClick={() => this.closeModal()}>X</a>
          </div>
        </Modal>
      </Row>
    )
  }
}

export default Confirm;
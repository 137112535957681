// Importantando o React
import React from "react";


class Footer extends React.Component{

    render = () => {
        
        return(
            <footer>
                <div className="logo"></div>
            </footer>
        )
    }
    
    
}

export default Footer;
// Importantando o React
import React from "react";
// Importantando o component Home
import Home from "./components/home/home";
import Login from "./components/login/login";
import { Container } from 'react-materialize';
import { Switch, Route } from 'react-router-dom'
import Authentication from './app/authentication'
import Consult from './components/consult/consult'
import Print from './components/print/print'
import Confer from './components/print/confer'
import Confirm from './components/confirm/confirm'

class Main extends React.Component{ 

  componentDidMount(){
    if(!Authentication.getSessionAuthentication() && window.location.pathname != '/login') {
        window.location.href = '/login'
    }
  }

  render = () => {
    return (
      <main id="main">
        <Container>
          <Switch>
            <Route exact path='/home' component={Home}/>
            <Route path='/login' component={Login}/>
            <Route path='/consult/:edit' component={Consult}/>
            <Route path='/confirm/:id/:segment?' component={Confirm}/>
            <Route path='/print/:id/:segment?' component={Print}/>
            <Route path='/confer/:id/:segment?' component={Confer}/>
          </Switch>
        </Container>
      </main> 
    )
  }
}

export default Main;
import React from "react";
import { Row, Button } from 'react-materialize';
import '../../css/print.css';
import Rotulo from '../rotulo/rotulo'
import Api from '../../app/api'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import HandleData from '../../util/handle_data'

class ReadMedia extends React.Component{ 

  state = {
    itemSelected: 0,
    data: null,
    midias:[],
    erroDisplay: false
  }

  constructor(props){
    super(props)
  }

  componentDidMount(){
    let firstData = this.props.data
    if(typeof firstData.NRO_PEDIDO_MOBY == 'string'){
      Api.getImg(firstData.FILIAL_ORIGEM, firstData.REQUISICAO_NRO, firstData.NRO_PEDIDO_MOBY, (imageData) => {
        if(HandleData.dataIsValid(imageData)){
          this.setState({midias: HandleData.handleData(imageData)})
        }else{
          this.setState({erroDisplay: true})
        }
      })
    }else{
      this.setState({erroDisplay: true})
    }
  }

  renderReadMedia = () => {
    return(
      <div>
        <div className="base-midias">
          <div className="arrow-left-midias"onClick={() => {
            this.setState({itemSelected: this.state.itemSelected <= 0 ? this.state.midias.length-1 : this.state.itemSelected-1})
          }}>&#x2039;</div>
          <div className="midias">
              {
                this.state.midias.map((item, index) => {
                  if(item.LINK.substr(item.LINK.lastIndexOf('.') + 1) == 'jpg'){
                    return (
                      <div key={index} className={`${index == this.state.itemSelected ? 'showMidia' : 'hideMidia'}`}>
                        <span><br />{index} - {item.NOME_ARQUIVO}</span>
                        <InnerImageZoom moveType="drag" className={`sizeMidia item-${index}`} key={index} src={item.LINK + '?v=' + new Date().getTime()} />
                      </div>
                    )
                  }


                  
                  if(item.LINK.substr(item.LINK.lastIndexOf('.') + 1) == 'm4a'){
                    return (
                      <div  key={index} className={`${index == this.state.itemSelected ? 'showMidia' : 'hideMidia'}`}>
                        <span><br />{index} - {item.NOME_ARQUIVO}</span>
                        <audio key={index} className={`sizeMidia item-${index}`} controls="controls" style={{height: '200px'}}>
                          <source src={item.LINK} type="audio/mp4" />
                        </audio>
                      </div>
                    )
                  }

                  if(item.LINK.substr(item.LINK.lastIndexOf('.') + 1) != 'jpg'){
                    return (
                      <div  key={index} className={`${index == this.state.itemSelected ? 'showMidia' : 'hideMidia'}`}>
                        <span><br />{index} - {item.NOME_ARQUIVO} </span>
                        <iframe key={index} className={`sizeMidia item-${index}`} src={"https://docs.google.com/gview?embedded=true&url=" + item.LINK} />
                      </div>
                    )
                  }

                  
                })
              }
          </div>
          <div className="arrow-right-midias" onClick={() => {
            this.setState({itemSelected: this.state.itemSelected >= this.state.midias.length-1 ? 0 : this.state.itemSelected+1})
          }}>&#x203A;</div>
        </div>
      </div>
    )
  }

  render() {
    return (
      this.state.erroDisplay == false ? this.renderReadMedia() : <h4 style={{marginTop:100}}>Nenhum receita encontrada</h4>
    )
  }
}

export default ReadMedia;
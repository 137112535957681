
import React from "react";
import { Card, Input, Button} from 'react-materialize';
import Authentication from '../../app/authentication'
import Api from '../../app/api'


class Login extends React.Component{

    state = {
        cpf: '',
        password: '',
        displayError: null,
        displayButton: 'LOGIN'
    }

    constructor(props){
        super(props)
    }

    componentDidMount(){
        
    }

    submitLogin = () => {
        if(this.handleErrorForm()){
            this.setState({displayButton: 'Aguarde...'})
            Api.getLogin(this.state.cpf, (data) => {
                if(Authentication.handleAuthentication(data, this.state.password)){
                    Authentication.authenticationSuccess(data)
                } else {
                    this.setState({displayButton: 'LOGIN'})
                    this.setState({displayError: 'Ocorreu um erro'})
                    this.setState({ displayError: "Cpf/senha incorreto!" })  
                }
            })
        }
    }

    handleErrorForm = () => {
        if(this.state.cpf.length <= 3) {
            this.setState({ displayError: "Por favor, digite um cpf válido" })
            return false;
        }

        if(this.state.password.length <= 3) {
            this.setState({ displayError: "Por favor, digite uma senha válida" })
            return false;
        }

        this.setState({ displayError: null })
        return true
    }

    
    render = () => {
        return (
            <div className="canvas-login">
                <Card className="card-login">
                    <div className="center center-position">
                        <span className="ic-logo ic-logo-position"></span>
                    </div>

                    <h3>Central de Rótulos</h3>
                    <div className="base-login">
                        <Input className="inputForm" type="email" label="CPF" onChange={(evt, value) => this.setState({cpf: evt.target.value})} defaultValue={this.state.cpf} />
                        <Input className="inputForm" type="password" label="Senha" onChange={(evt, value) => this.setState({password: evt.target.value})}  defaultValue={this.state.password}/>
                        <Button onClick={this.submitLogin} waves='light' className="grey custon-button">{this.state.displayButton}</Button>
                    </div>
                    <span style={{display: this.state.displayError == null ? "none" : "block" }} className="display-error">{this.state.displayError}</span>

                </Card>
            </div>
        )
    }
}

export default Login;
import React from "react";
import { Row } from 'react-materialize';
import { Button} from 'react-materialize';
import InputMask from 'react-input-mask';


class Consult extends React.Component {

    state = {
        code: null
    }

    constructor(props){
        super(props)
    }

    convertCode(code){
        let end = code.substring(code.length -1, code.length) 
        let midle = code.substring(4, code.length-2) 
        let start = code.substring(0, 4) 
        return `${start}-${midle}-${end}`

        // Configuracao padrao
        // let str = this.getCodeEAN13(code)
        // if(str != null){
        //     let start = parseInt(str.substring(0, 3))
        //     let midlle = str.substring(3, 9) 
        //     let end = str.substring(str.length -3, str.length-2) 

        //     let code = `${start}-${midlle}-${end}`
        //     console.log(code.length)
        //     if(code.length == 11){
        //         code = `00${code}`
        //     } else if(code.length == 10){
        //         code = `000${code}`
        //     }
        //     return code
        // }
    }

    getCodeEAN13(ean) {
        if(ean == null) return
        var soma = 0;
        for (var i = 0; i < ean.length; i++) {
            if ((i % 2) === 0) {
                soma += ean.charAt(i) * 1;
            } else {
                soma += ean.charAt(i) * 3;
            }
        }

        

        var resultado = Math.floor(soma / 10) + 1;
        resultado *= 10;
        resultado -= soma;

        if ((resultado % 10) === 0) {
            ean = ean + '0';
        } else {
            ean = ean + resultado;
        }


        return ean;
    }

    goToPrint = () =>{
        var code = this.state.code
        code = code.replaceAll('_', '')
        if(code.length == 14){
            code = code.replaceAll('-', '')
            code = this.convertCode(code)
        }

        const goToUrl = this.props.match.params.edit != 'print' ? `/confirm/${code}/` :  `/confer/${code}/`
        console.log(goToUrl)
        window.location.href = goToUrl
    }

    render = () => {
        return (
            <Row>
                <div className="detail-page">
                    {this.props.match.params.edit == 'print' ? 'Consultar Rótulo' :  'Imprimir/Editar Rótulo'}
                </div>

                <div className="base-input-code">
                    <InputMask autoFocus mask="9999-999999-**" className="inputForm input-code-consult" type="text" placeholder="Escanear o código. Ex: 0001-830390-0"  onChange={(evt) => this.setState({code: evt.target.value}) } onKeyDown={
                        (e) => { 
                            e.key === 'Enter' ? this.goToPrint() : console.log(this.state.code) 
                        }
                    } />

                    <Button onClick={this.goToPrint} className="button-code-consult">Buscar</Button>
                </div>
            </Row>
        )
    }
}

export default Consult;





import Authentication from "./authentication"
import HandleData from '../util/handle_data'
import md5 from "react-native-md5"
import Util from '../util/util'

class Api {
    static getURL = () => {
        if(Util.isHml()){
            return 'https://almofariz.websiteseguro.com/ws.fcerta/wBusca2.php'
        }
        return 'https://almofariz.websiteseguro.com/ws.fcerta/wBusca.php'
    }

    static getUrlMakePdf = () => {
        return 'https://labelmaker.com.br/labelmaker/labelMaker.php'
        if(Util.isHml()){
            // return 'http://localhost:8002/labelMaker.php'
            return 'https://hml.almofarizlabelmaker.com.br/labelmaker/labelMaker.php'
        }
        return 'https://almofarizlabelmaker.com.br/labelmaker/labelMaker.php'
    }


    static optionXML(){
        var he = require('he');
        return {
            attributeNamePrefix : "@_",
            attrNodeName: "attr", //default is 'false'
            textNodeName : "#text",
            ignoreAttributes : false,
            ignoreNameSpace : false,
            allowBooleanAttributes : true,
            parseNodeValue : false,
            parseAttributeValue : true,
            trimValues: true,
            cdataTagName: "__cdata", //default is 'false'
            cdataPositionChar: "\\c",
            parseTrueNumberOnly: false,
            arrayMode: false, //"strict"
            attrValueProcessor: (val, attrName) => he.decode(val, {isAttributeValue: true}),//default is a=>a
            tagValueProcessor : (val, tagName) => he.decode(val), //default is a=>a
            stopNodes: ["parse-me-as-string"]
        }
    }

    static getTodayString(){
        const month = (new Date().getMonth()+1) < 10 ? `0${new Date().getMonth()+1}` : new Date().getMonth()+1
        const day = (new Date().getDate()) < 10 ? `0${new Date().getDate()}` : new Date().getDate()
        const year = new Date().getFullYear()
        return  `${year}${month}${day}`
    }

    static getKey() {
        return `${md5.hex_md5("ProjetoRotulo")}@|@${md5.hex_md5('172.67.147.112')}@|@${md5.hex_md5(Api.getTodayString())}`;
    }

    static hadleFetch(webBusca, items){
        let endpoint = Api.getURL()
        let formData = new FormData();
        formData.append('KEY', Api.getKey());
        formData.append('webBUSCA', webBusca);
        Object.keys(items).forEach(function(key) {
            formData.append(key, items[key]);
        });
        return fetch(endpoint, {
            method: "POST",
            body: formData
        })
    }

    static parseXMLToObject(text){
        let data = new TextDecoder("ISO-8859-1").decode(text);
        
        var parser = require('fast-xml-parser');
        if( parser.validate(data) === true) {
            var jsonObj = parser.parse(data, Api.optionXML());
        }
        var tObj = parser.getTraversalObj(data, Api.optionXML());
        var jsonObj = parser.convertToJson(tObj, Api.optionXML());
        return jsonObj;
    }


    static getBusca = (params, callback, errorCallback) => {        
        let paramsArray = params.split('-')
        let filial = paramsArray[0]
        let requiNro = paramsArray[1]
        let requiSerie = paramsArray[2]

        Api.hadleFetch('REQsD', {
            FILIAL_O: filial,
            REQUI_NRO: requiNro,
            REQUI_SERIE: requiSerie,
        }).then(response => response.arrayBuffer()) 
            .then(responseText => Api.parseXMLToObject(responseText))
            .then(data => {
                HandleData.dataIsValid(data.REQsD) ? callback(data.REQsD) : errorCallback()
            })
    }

    static getImg = (filial, requiNro, pedidoNro, callback) => {
        Api.hadleFetch('imgREC', {
            FILIAL_O: filial,
            REQUI_NRO: requiNro,
            PEDIDO_NRO: pedidoNro,
            SESS: Authentication.getSessionAuthenticationData().SESSAO
        }).then(response => response.arrayBuffer()) 
            .then(responseText => Api.parseXMLToObject(responseText))
            .then(data => {
                callback(data.imgREC)
            })
    }

    static getLogin(cpf, callback) {
        Api.hadleFetch('LOGIN', {
            CPF: cpf,
        }).then(response => response.arrayBuffer()) 
            .then(responseText => Api.parseXMLToObject(responseText))
            .then(data => {
                callback(data.LOGIN)
            })
    }

    static editRotulo(params, posologia, formula, dosagem, medTipo, callback){
    
        let paramsArray = params.split('-')
        let filial = paramsArray[0]
        let requiNro = paramsArray[1]
        let requiSerie = paramsArray[2]
        let cpf = Authentication.getSessionAuthenticationData().CPF;

        Api.hadleFetch('gROTEDT', {
            FILIAL_E: filial,
            NRO_REQ: requiNro,
            SERIE: requiSerie,
            POSOLOG: posologia,
            FORMULA: formula,
            DOSAGEM: dosagem,
            USER_EDICAO: cpf,
            MED_TIPO: medTipo
        }).then(response => response.arrayBuffer()) 
            .then(responseText => Api.parseXMLToObject(responseText))
            .then(data => {
                callback(data.gROTEDT)
            })
    }

    static makePdf(rotulo, dataForm, callback){
        let formData = new FormData();
        formData.append('rotulo', rotulo);
        formData.append('data', JSON.stringify(dataForm));

        fetch(Api.getUrlMakePdf(), {
            method: "POST",
            body: formData
        }).then(response => response.json() ).then(data => {
            callback(data)
        });
    }
}

export default Api

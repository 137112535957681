import React, {useEffect, useState, useRef} from "react";


const FormulaView = (props) => {

    const [itens, setItens] = useState([]);
    const [data, setData] = useState(null)

    useEffect(() => {
        if(props.data != null && data == null){
            setData(props.data)
        }
        if(data != null && data != '' && itens.length == 0){
            const formatData = data.substr(data.length - 1) == ';' ? data.slice(0, -1) : data
            setItens(formatData.split(';'))
        }
    });

    const addItem = () => {
        var newItem = window.prompt("Adicione um nome componenete:")
        if(typeof newItem == 'string'){
            newItem = newItem.toUpperCase()
            const newItens = [...itens, newItem]
            setItens(newItens)
            updateData(newItens)
        }
    }

    const removeItem = (indexItem) => {
        const newItens = itens.filter((item, index) => {return indexItem != index})
        setItens(newItens)
        updateData(newItens)
    }   

    const updateValue = (index, value) => {
        itens[index] = value
        updateData(itens)
    }

    const updateData = (itens) => {
        props.updateDate(`${itens.join(';')};`)
    }

    const renderFormulaView = () => {
        return (
            <>
                <ul>
                    {itens.map((item, indexItem) => {
                        return  (
                            <li key={item}> 
                                <input defaultValue={item.trim() || ''} placeholder={item.trim()} onKeyDown={(e) => e.key == 'Enter' ? updateValue(indexItem, e.target.value) : '' } onBlur={(e) => {updateValue(indexItem, e.target.value)}} />
                                <span>
                                    <a className='removeItem' onClick={(() => { removeItem(indexItem) })}>X</a>
                                </span>
                            </li>
                        )
                    })}
                </ul>
                <a onClick={addItem}>Adicionar Item</a>
            </>
        )
    }


    return (
        <div className="FormulaView">
        { itens.length == 0 ? <center>Carregando...</center> : renderFormulaView() } 
        </div>
    )
}

export default FormulaView;
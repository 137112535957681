// Importantando o React
import React from "react";
import { useHistory } from "react-router-dom";

class Header extends React.Component {


  render = () => {
    if(window.location.pathname == '/login'){
      return null
    }

    return (
      <header>
        <a className="buttonBack" onClick={() => { window.history.back() }}>&#8249;</a>
        <a className="center center-position" href="/home">
            <span className="ic-logo ic-logo-position"></span>
        </a>
        <h5>Central de Rótulos</h5>
      </header>
    )
  }
  
}

export default Header;